Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions";
exports.labelBodyText = "TermsAndConditions Body";

exports.btnExampleTitle = "CLICK ME";
exports.getTermsAndConditionWebApiEndPoint="bx_block_help_center/terms_and_conditions/get_terms_and_conditions"
exports.getPrivicyPolicyApiEndPoint="bx_block_help_center/privacy_policies/";
exports.getPolicyApiEndPoint="bx_block_help_center/privacy_policies/get_privacy_policies";
exports.errorMsgBlankField = " cannot be blank";
exports.alertTypeSuccess = "success";
exports.alertTypeError = "error";
exports.descriptionText = "Description";
exports.examplePutAPiMethod = "PUT";
exports.policyUpdateSuccessMsg = "Policy updated successfully!";

exports.getCancellationPolicyApiEndpoint = "bx_block_help_center/cancellation_policies/latest_cancellation_policy";
exports.getCancellationPolicyBasicApiEndpoint = "bx_block_help_center/cancellation_policies/";
exports.cancellationPolicyUpdateSuccessMsg = "Cancellation Policy updated successfully!";

exports.getAboutUsApiEndpoint = "bx_block_help_center/abouts/get_about_us";
exports.getAboutUsBasicApiEndpoint = "bx_block_help_center/abouts/";
exports.getFaqApiEndpoint = "bx_block_faqs/faqs";

exports.getRefundPolicyApiEndpoint = "bx_block_help_center/refund_policies/latest_refund_policy";
exports.getRefundPolicyBasicApiEndpoint = "bx_block_help_center/refund_policies/";
exports.refundPolicyUpdateSuccessMsg = "Refund Policy updated successfully!";

exports.getTermsAndConditionsApiEndpoint = "bx_block_help_center/terms_and_conditions/get_terms_and_conditions";
exports.getTermsAndConditionsBasicApiEndpoint = "bx_block_help_center/terms_and_conditions/";
exports.termsAndConditionsUpdateSuccessMsg = "Terms and Conditions updated successfully!";

exports.errorMsgBlankField = " cannot be blank";
exports.alertTypeSuccess = "success";
exports.alertTypeError = "error";
exports.descriptionText = "Description";
exports.topicDescriptionText = "Topic Description";
exports.nameText = "Topic Name";
exports.examplePutAPiMethod = "PUT";
exports.aboutUsUpdateSuccessMsg = "About Us updated successfully!";
exports.howItWorksUpdateSuccessMsg = "How It Works updated successfully!";
exports.invalidTokenMessage = "Invalid token";
exports.howItWorkListApiEndPoint = "bx_block_help_center/how_it_works";
exports.updateText = "Update";
exports.cancelText = "Cancel";
exports.sessionExpireMsg = "Session is expired. Please login again.";
exports.swTermsandConditionApiEndPoint = "/bx_block_help_center/terms_and_conditions";
exports.swRefundPolicyApiEndPoint = "/bx_block_help_center/refund_policies/latest_refund_policy";
exports.swPrivacyPolicyApiEndPoint = "/bx_block_help_center/privacy_policies/get_privacy_policies";
exports.swHowItWorksApiEndPoint = "/bx_block_help_center/how_it_works";
exports.swAboutUsApiEndPoint = "/bx_block_help_center/abouts/get_about_us";
exports.swFaqsApiEndPoint = "/bx_block_faqs/faqs/list_faqs";
exports.faqsTitle = "Faq's";
exports.faqSubTitle = 'We are here to help you , don’t hesitate to ask any question';
exports.faqScreenHeading = "Frequently Asked Questions";
exports.seeMoreText = "See More";
exports.swCancellationPolicyApiEndPoint = "/bx_block_help_center/cancellation_policies/latest_cancellation_policy"
exports.termsAndConditonSubTitle = "Save and curate your desired courses, and easily plan your learning journey and access to your favorite educational resources with just a click."
// Customizable Area End