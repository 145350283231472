// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../couponcodegenerator/src/VouchercodegeneratorController";
import { elementsPerPage } from "../../../components/src/MainLayout.web";

export const configJSON = require("./config");
export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string;
    navigate: (value: string) => void;
  };
  id: string;
  // Customizable Area End
}

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface CoursesResponse {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  id: string;
  type: string;
  attributes: DatumAttributes;
}

export interface DatumAttributes {
  course_order: CourseOrder[];
}

export interface CourseOrder {
  id: number;
  order_id: number;
  course_id: number;
  course_type: string;
  price: number;
  no_of_year: number;
  place_order_date: Date;
  created_at: Date;
  updated_at: Date;
  is_course: boolean;
  is_coupon_apply: boolean;
  course_count: number;
  date: string;
  time: string;
  invoice_url: null;
  course_data: CourseData;
}

export interface CourseData {
  data: Data;
}

export interface Data {
  id: string;
  type: string;
  attributes: DataAttributes;
}

export interface DataAttributes {
  course_name: string;
  short_description: string;
  full_description: string;
  account_id: number;
  first_year_price: number;
  second_year_price: number;
  discounted_price: number | null;
  preview_video_url: string;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  trending: boolean;
  what_learn: string[];
  course_sub_category: CourseSubCategory;
  course_child_category: CourseChildCategory;
  review: Review[];
  created_on: string;
  student_count: number;
  course_category: CourseCategory;
  instructor_name: string;
  instructor_details: InstructorDetails;
  language_name: string;
  total_content: number;
  average_rating: AverageRating;
  specfifc_content_duration: null;
  course_thumbnail: string;
  all_course_class_duration: number;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  course_class_count: number;
}

export interface AverageRating {
  id: number;
  course_name: string;
  duration: null;
  year: null;
  created_at: Date;
  updated_at: Date;
  short_description: string;
  current_price: null;
  price: null;
  video_url: null;
  full_description: string;
  multiple_videos: null;
  course_category_id: number;
  valid_for_discount: null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: number;
  discounted_price: number | null;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number;
  language_options_language_id: number;
  Rating: { [key: string]: number };
  Average_rating: number;
  total_rating_count: number;
  total_review_count: number;
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface CourseChildCategory {
  id: number;
  course_category_id: number;
  course_sub_category_id: number;
  name: string;
  total_courses: null;
  total_students: null;
  status: boolean;
  color: string;
  created_at: Date;
  updated_at: Date;
}

export interface CourseSubCategory {
  id: number;
  name: string;
  course_category_id: number;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  total_courses: null;
  total_subjects: null;
}

export interface InstructorDetails {
  name: string;
  details: null | string;
  qualifications: null | string;
  leacture_in: null | string;
  profile_icon: string;
}

export interface Review {
  id: number;
  content: string;
  rating: string;
  account_id: number;
  reviewable_id: number;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  visible_on_landing: boolean;
}

export interface Meta {
  message: string;
}

interface S {
  // Customizable Area Start
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isLoading: boolean;
  searchText: string;
  coursesList: Datum[];
  listView: "list" | "card";
  page: number;
  perPage: number;
  loadMoreDisabled: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class MyCourseController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      page: 1,
      perPage: elementsPerPage,
      alertMsg: "",
      listView: "card",
      searchText: "",
      alertType: "success",
      isAlert: false,
      isLoading: false,
      userToken: "",
      coursesList: [],
      loadMoreDisabled: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        if (apiRequestCallId === this.getCoursesApiCallId) {
          this.handleGetCoursesResponse(responseJson);
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async handleUserToken() {
    const token = await getStorageData("loginToken");
    if (token) {
      this.setState({ userToken: token });
      this.getCourses();
    } else {
      this.navigationLoginScreen();
    }
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleSearchChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    this.setState({ searchText: e.target.value });
  }

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  getCourses() {
    this.setState({ isLoading: true });
    this.getCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getAllCoursesApiEndpoint +
        `?search=${this.state.searchText}`,
      method: configJSON.getMethod,
      token: this.state.userToken,
      contentType: configJSON.apiContentType
    });
  }

  oncloseAlert() {
    this.setState({
      isAlert: false
    });
  }

  changeDisplayListing(type: "list" | "card") {
    this.setState({ listView: type });
  }

  handleGetCoursesResponse(responseJson: CoursesResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        coursesList: [...this.state.coursesList, ...responseJson.data],
        loadMoreDisabled: responseJson.data.length < elementsPerPage,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false, loadMoreDisabled: true });
    }
  }

  // Customizable Area End
}
// Customizable Area End
