import React from "react";

// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { customAlert, getStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("../../blocks/categoriessubcategories/src/config");
import { Color } from "@material-ui/lab/Alert";

interface ResponseErrors {
  errors: {
    token: string;
  }[]
}
export interface CourseAttributes {
    id: string;
    status:boolean;
    course_thumbnail: string;
    bundle_course_thumbnail?:string;
    course_category:{
      name: string
    };
    rating: string;
    total_student?: string;
    bundle_name: string;
    course_child_category: {
      color: string;
      name: string;
    };
    is_wishlist: string;
    course_name: string;
    instructor_name: string;
    first_year_price: number;
    second_year_price: number;
    is_purchased: boolean;
    short_description?: string;
    language_name?: string;
    total_content?: string;
    lectures?: string;
    all_course_class_duration: number;
    is_cart: boolean;
    student_count:number;
    average_rating: AverageRating;
    course_class_count: string;
  }
  export interface AverageRating {
    id:                           number;
    Average_rating:               number;
}
  export interface WishlistResponse {
    meta?: {
      message: string;
    },
    message?: string
  }
  export interface AddCartResponse {
    message: string;
  }
 export interface Course {
    id: string;
    attributes: CourseAttributes;
    type: string;
  }

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  courses:Course[];
  listView?: boolean;
  gridView?: boolean;
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}


interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  hoveredIndex: number | null;
  Courses: Course[];
  alertType: Color;
  alertMsg: string;
  isAlert: boolean;
  CourseValidityDialog: boolean;
  courseValidityDetails: any;
  indexCourse: number;
  selectedValidity: number;
  courseListname: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CourseCardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  toggleWishlistApiCallId: string = "";
  addToChartApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: false,
      userToken: "",
      hoveredIndex: null,
      Courses: this.props.courses,
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      CourseValidityDialog: false,
      courseValidityDetails: {},
      indexCourse: 0,
      selectedValidity: 1,
      courseListname: "newLaunchCourses"
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.addToChartApiCallId:
              this.handleChartStatusResponse(responseJson);
              break;
            case this.toggleWishlistApiCallId:
              this.handleWishlistStatusResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
 
  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.setState({
        alertMsg: configJSON.sessionExpireMsg,
        isAlert: true,
        alertType: "error"
      });
      alert(configJSON.sessionExpireMsg);
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  
  handleMouseLeave = () => {
    this.setState({ hoveredIndex: -1 });
  };

  handleMouseEnter = (index: number) => {
    this.setState({ hoveredIndex: index });
  };

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }
  handleWishlistStatus(isAdd: boolean, courseId: string, index: number) {
    const {userToken} = this.state;
    if(!userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your wishlist.",
        alertType: "info",
        isAlert: true
      });
      return;
    }
    const headers = {
      "Content-Type": !isAdd ? configJSON.categoryApiContentType : undefined,
      "token": this.state.userToken
    };

    const body = {
      wishlist: {
        course_id: courseId
      }
    };
    const formdata = new FormData();
    formdata.append("course_id", courseId);

    let clone = [...this.state.Courses];
    clone[index].attributes.is_wishlist = `${!isAdd}`;

    const stateToSet: unknown = { ["Courses"]: clone };

    this.setState(stateToSet as Pick<S, keyof S>);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.toggleWishlistApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !isAdd ? configJSON.wishlistApiEndpoint : configJSON.removeWishlistApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !isAdd ? JSON.stringify(body) : formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      !isAdd ? "POST" : "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenCourseValidityPopup = (isAdd: boolean,course: Course,index: number, list: "newLaunchCourses" | "trendingCourses" | "bundleCourses") => {
    if (isAdd) {
      return;
    }
    this.setState({courseListname: list,indexCourse: index, courseValidityDetails: course}, () => {
      this.setState({ CourseValidityDialog: true})
    })
  }

  onClose = () => {
    this.setState({ CourseValidityDialog: false})
  }

  handleChartStatus(isAdd: boolean, courseId: string,type: string, index: number,no_of_year: number = 1  ) {
    if (isAdd) {
      this.setState({ alertMsg: "Redirect to cart", isAlert: true, alertType: "info" });
      this.navigationShoppingCart()
      return;
    }
    const {userToken} = this.state;
    
    if(!userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your cart.",
        alertType: "info",
        isAlert: true
      });
      return;
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.userToken
    };
    const body = {
      course_id: courseId,
      no_of_year,
      text: type
    };

    let clone = [...this.state["Courses"]];
    clone[index].attributes.is_cart = true;

    const stateToSet: unknown = { ["Courses"]: clone };

    this.setState(stateToSet as Pick<S, keyof S>);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToChartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToChartApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleWishlistStatusResponse(response: WishlistResponse) {
    if(response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
    }
    if (response && response.meta) {
      this.setState({
        alertMsg: response.meta.message,
        isAlert: true,
        alertType: "success"
      });
    }
  }
  async handleChartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      await this.updateCartCount()
      this.setState({
        CourseValidityDialog: false,
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
    }
  }
  updateCartCount = async () => {
    try {
      let cartCount = await getStorageData('cartCount'); 
  
      if (cartCount) {
        cartCount = parseInt(cartCount) + 1; 
      } else {
        cartCount = 1;
      }
  
      await setStorageData('cartCount', cartCount);
      const event = new CustomEvent('cartCountChange', { detail: { count: cartCount } });
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error updating cart count:', error);
    }
  }
  oncloseAlert =() => {
    this.setState({
      isAlert: false,
    });
  }

  navigationShoppingCart = () => {
    const shoppingCartScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationShoppingCartMessage)
    );
    shoppingCartScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(shoppingCartScreenMsg);
  };
  handleSelect = (validity: 1 | 2) => {
    this.setState({ selectedValidity: validity });
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
