import React, { Component } from 'react';
import { styled } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import { removeStorageData } from 'framework/src/Utilities';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { FiDownload, FiPhone, FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface CustomDrawerProps {
  userPic: string;
  fullName: string;
  pathname: string;
  phoneNumber: string;
}

interface CustomDrawerState {}

const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
  color: 'inherit',
  '& .MuiSvgIcon-root': {
    width: '24px !important',
    height: '24px !important'
  },
});
const itemsList = [
  {
    label: 'My Profile',
    icon: <PersonOutlinedIcon />,
    href: '/UserProfile'
  },
  {
    label: 'Notification',
    icon: <NotificationsNoneOutlinedIcon />,
    href: '/Notifications'
  },
  {
    label: 'My Purchases',
    icon: <ShoppingCartOutlinedIcon />,
    href: '/Purchases'
  },
  {
    label: 'Downloads',
    icon: <FiDownload fontSize={24} />,
    href: '/Downloads'
  },
  {
    label: 'My Wishlist',
    icon: <FavoriteBorderIcon />,
    href: '/Wishlist'
  },
  {
    label: 'My Cash',
    icon: <StarsOutlinedIcon />,
    href: '/MyCash'
  },
  {
    label: 'Contact us',
    icon: <FiPhone fontSize={24} />,
    href: '/ContactUs'
  },
  {
    label: 'Logout',
    icon: <FiLogOut fontSize={24} />,
    href: '/?logout_user=true',
    callback: () => {
      removeStorageData('emailOTPToken');
      removeStorageData('loginEmail');
      removeStorageData('profilePic');
      removeStorageData('profileUsername');
      removeStorageData('userRole');
      removeStorageData('cartCount');
      removeStorageData('wishlistCount');
      removeStorageData('coupon_code');
      removeStorageData('discount_type');
    },
  },
];

class UserSidebarMenu extends Component<CustomDrawerProps, CustomDrawerState> {
  constructor(props: CustomDrawerProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        style={{ width: '100%', background: '#FFEFE7', borderRadius: '8px' }}
      >
        <div
          style={{
            borderBottom: '2px solid #FF8256',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 130,
            background: '#FFDBC9',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            fontSize: 16,
            gap: 3,
          }}
        >
          <Avatar
            style={{ width: 64, height: 64 }}
            src={this.props.userPic}
            alt=""
          />
          {this.props.fullName ? (
            <strong style={{ margin: 2 }}>{this.props.fullName}</strong>
          ) : (
            <Skeleton variant="text" style={{ width: '60%' }} />
          )}
          {this.props.phoneNumber !== '+91 ' ? (
            <span style={{ fontSize: 14 }}>{this.props.phoneNumber}</span>
          ) : (
            <Skeleton variant="text" style={{ width: '70%' }} />
          )}
        </div>
        <List>
          {itemsList.map(item => {
            const isSelected = item.href === this.props.pathname;
            return (
              <ListItem
                button
                component={Link}
                onClick={item.callback ? () => item.callback() : undefined}
                to={item.href}
                key={item.label}
                data-test-id={item.label}
                style={{
                  paddingLeft: 25,
                  color: isSelected ? '#206575' : 'black',
                  ...(isSelected
                    ? {
                        fontWeight: 'bold',
                        background: 'transparent'
                      }
                    : {}),
                }}
              >
                <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}

export default UserSidebarMenu;
